.qmMonthLabel {

	font-size: 1.3em;
	margin: 0 8px;

	.active {
		font-size: 1.4em;
	}

}

@media only screen and (min-width: 1200px) {
	.calendar.fluid .cal-events,
	.calendar.largest .cal-events {

		margin: 0;
		padding: 0;
		width: 100%;

		li {
			display: block;
			margin: 0 4px 4px 4px;
		}

	}
}

.dashboard-event-row {

	.tag {

		display: block;

	}

}

.agenda-next {
	width: 30px;
	padding-right: 4px;
}

.agenda-close {
	right: 30px;
	padding-right: 0;
}

.agenda-slot {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
}

.agenda-event,
.cal-events li {
	cursor: pointer;
}
