.nav-tabs {
	.nav-link {

		padding: 0.5rem 0.9rem;
		font-size: 0.8rem;
		position: relative;

		&.active {

			box-shadow: 0 1px 4px rgba(0,0,0,0.25);

			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 100%;
				height: 8px;
				background-color: #FFF;
				left: 0;
				bottom: -8px;
				box-shadow: none;
			}

		}

	}
}

.tab-content {
	border: 1px solid #ddd;
	border-top: none;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 1px 4px rgba(0,0,0,0.25);
}

.fieldset {

	border-radius: 5px;

	.container-fluid {
		padding: 0;
	}

	form {

		.input-group {

			.input-group-prepend {

				width: 30%;
			}

			.input-group-prepend,
			.input-group-append {

				* {
					width: 100%;
					border-radius: 0;
					border-bottom-width: 0;
					font-size: 0.9rem;
				}

			}

			.form-control {
				border-radius: 0;
				border-bottom-width: 0;
				font-size: 0.9rem;
			}

			// TOP FORM ELEMENT IN GROUP
			&:first-child {
				.form-control {
					border-top-right-radius: 5px;
				}
			}

			// BOTTOM FORM ELEMENT IN GROUP
			&:last-child {

				* {
					border-bottom-width: 1px;
				}

				> :last-child, > :last-child * {
					border-bottom-right-radius: 5px;
				}

			}

		}

	}

}

.calendar {
    caption {
        caption-side: top;
    }
}