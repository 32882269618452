.dropdown-basic {
	background-color: rgb(227, 229, 234) !important;
	width: 100% !important;
	border-radius: 0px !important;
	border-color: black !important;
	color: black !important;
	height: 100% !important;
}

.dropdown-basic::-webkit-scrollbar {
	display: none;
}

.printForm {
	display: flex;
	justify-content: space-between !important;
}

.printFormGroup {
	border: solid 1px black;
	border-radius: 5px;
	padding: 10px;
}

.printFormControl {
	width: 50% !important;
}

.settingButton {
	bottom: 0px;
	position: absolute;
}

.modalHeader {
	text-align: center;
	justify-content: center !important;
	background-color: rgb(227, 229, 234);
	font-size: medium;
	padding: 0.5rem 0.5rem !important;
}

.modalHeaderAfterPrint {
	text-align: center;
	justify-content: center !important;
	background-color: rgb(227, 229, 234);
	font-size: medium;
	padding: 0.5rem 0.5rem !important;
	padding: 0px !important;
}

.modalTitle {
	font-size: medium;
}

.printContainer {
	padding: 10px;
}

.printHeader {
	background-color: rgb(227, 229, 234);
	border-radius: 10px;
	margin: 10px;
	padding: 10px 10px 40px 10px;
}

.dataHeader {
	background-color: rgb(227, 229, 234);
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 10px;
}

.dataDetailHeader {
	background-color: rgb(227, 229, 234);
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
}

.detailed-info {
	border: 1px solid;
	border-color: black;
	border-radius: 5px;
	padding: 10px;
	margin: 10px;
}

.labelStyle {
	font-size: small;
	font-weight: bold;
}

.dataStyle {
	font-size: small;
}

.dataStyleStress {
	font-size: small;
	border-bottom: 1px solid black;
}

// .modal-content {
// 	width: 1000px 
// 	!important;
// }

.printButton {
	position: absolute;
	right: 20px;
	padding-right: 15px;
	display: flex;
}

.marginTop10 {
	margin-top: 10px;
}

._row {
	// display: -ms-flexbox;
	display: flex;
	// -ms-flex-wrap: wrap;
	// flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px
}

@media (min-width:768px) {
	._col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	._col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	._col-md-1 {
		flex: 0 0 8.333333%;
		-ms-flex: 0 0 8.333333%;
		max-width: 8.333333%
	}

	._col-md-2 {
		flex: 0 0 16.666667%;
		-ms-flex: 0 0 16.666667%;
		max-width: 16.666667%
	}

	._col-md-3 {
		flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		max-width: 25%
	}

	._col-md-4 {
		flex: 0 0 33.333333%;
		-ms-flex: 0 0 33.333333%;
		max-width: 33.333333%
	}

	._col-md-5 {
		flex: 0 0 41.666667%;
		-ms-flex: 0 0 41.666667%;
		max-width: 41.666667%
	}

	._col-md-6 {
		flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		max-width: 50%
	}

	._col-md-7 {
		flex: 0 0 58.333333%;
		-ms-flex: 0 0 58.333333%;
		max-width: 58.333333%
	}

}

._col,
._col-1,
._col-10,
._col-11,
._col-12,
._col-2,
._col-3,
._col-4,
._col-5,
._col-6,
._col-7,
._col-8,
._col-9,
._col-auto,
._col-lg,
._col-lg-1,
._col-lg-10,
._col-lg-11,
._col-lg-12,
._col-lg-2,
._col-lg-3,
._col-lg-4,
._col-lg-5,
._col-lg-6,
._col-lg-7,
._col-lg-8,
._col-lg-9,
._col-lg-auto,
._col-md,
._col-md-1,
._col-md-10,
._col-md-11,
._col-md-12,
._col-md-2,
._col-md-3,
._col-md-4,
._col-md-5,
._col-md-6,
._col-md-7,
._col-md-8,
._col-md-9,
._col-md-auto,
._col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px
}


@media print {
	._col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%
	}

	._col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%
	}

	._col-md-1 {
		flex: 0 0 8.333333%;
		-ms-flex: 0 0 8.333333%;
		max-width: 8.333333%
	}

	._col-md-2 {
		flex: 0 0 16.666667%;
		-ms-flex: 0 0 16.666667%;
		max-width: 16.666667%
	}

	._col-md-3 {
		flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		max-width: 25%
	}

	._col-md-4 {
		flex: 0 0 33.333333%;
		-ms-flex: 0 0 33.333333%;
		max-width: 33.333333%
	}

	._col-md-5 {
		flex: 0 0 41.666667%;
		-ms-flex: 0 0 41.666667%;
		max-width: 41.666667%
	}

	._col-md-6 {
		flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		max-width: 50%
	}

	._col-md-7 {
		flex: 0 0 58.333333%;
		-ms-flex: 0 0 58.333333%;
		max-width: 58.333333%
	}

}